import { JigsawPuzzle } from "react-jigsaw-puzzle"
import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";
import riddl5img from "../images/cute_paris.jpg"
export function Riddle4(){
    return(
        <div>
        <h4 className="riddle-text">There is an envelope hidden somewhere. Find it and solve what's inside</h4>
        {/* <JigsawPuzzle imageSrc={riddl5img} rows={8} columns={8}/> */}
        </div>
    )
}