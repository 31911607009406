import { useEffect } from "react"
import a from '../images/cute_together.jpg'

export function Riddle2(){
    
    return(
        <div>
        <h5 class="fade-out riddle-text">Pretty good! Lets go find the magnet. In the magnet is a box in the box is another riddle. When you solve chuck the answer in here</h5>
        <h4 >What is bla bla?</h4>
        </div>
    )
}