import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { Riddle1 } from './Riddles/riddle1';
import { Riddle2 } from './Riddles/riddle2';
import { Riddle3 } from './Riddles/riddle3';
import { Riddle4 } from './Riddles/riddle4';
import { Riddle5} from './Riddles/riddle5';
import riddle2_backgroundimg from './images/cute_together.jpg';
import riddle1_backgroundimg from './images/cute_desk.jpg';
import riddle3_backgroundimg from './images/cute_butt.jpg';
import riddle4_backgroundimg from './images/cute_laughing_possible_riddle.jpg';
import riddle5_backgroundimg from './images/cute_crazy.jpg';
import finish_img from './images/cute_finish.jpg';
import Countdown from 'react-countdown';



function App() {

  const[riddleCount, setRiddleCount] = useState(0);
  const[correct, setCorrect] = useState(false);
  const[taunt, setTaunt] = useState('');
  const[displaySupportVideo, setDisplaySupportVideo] = useState(false);
  const[ready, setReady] = useState(false);
  const[timerComplete, setTimerComplete] = useState(false);
  const [answer, setAnswer] = useState('');
  const [finished, setFinished] = useState(false);
  const finishDate = new Date(2024, 11, 21, 16, 45, 0);
  var riddles = [
    {riddle: Riddle1(), answer: "bla bla", backgroundImg: riddle1_backgroundimg},
    {riddle: Riddle2(), answer:"def", backgroundImg: riddle2_backgroundimg},
    {riddle: Riddle3(), answer:"yes", backgroundImg: riddle3_backgroundimg},
    {riddle: Riddle4(), answer:"ok", backgroundImg: riddle4_backgroundimg},
    {riddle: Riddle5(), answer:"ok", backgroundImg: riddle5_backgroundimg}
  ]
  const[currentRiddle, setCurrentRiddle] = useState(riddles[riddleCount])
  const WrongResponse = ['booo!', 'you suck!', 'Go eat poop!', 'Not even close.', 'You\'re embarassing yourself.', 'Do you need to listen to the motivational song again?', 'Do you even like riddles?']
  function submitAnswer(){
    if(answer.toLocaleLowerCase() == currentRiddle.answer.toLowerCase()){
        setCorrect(true);
        setAnswer('');
        
    }
    else{
        setCorrect(false);
        setTaunt(WrongResponse[Math.floor(Math.random() * WrongResponse.length)]);
    }
   
}
useEffect(() => {
  if(finished){
    document.getElementsByClassName("App")[0].style.backgroundImage = `url('${finish_img}')`;
  }
  else{
    document.getElementsByClassName("App")[0].style.backgroundImage = `url('${currentRiddle.backgroundImg}')`;
  }
  
  if(new Date() > finishDate){
    setTimerComplete(true);
  }
},[currentRiddle, finished])
useEffect(() => {
  var intro = document.getElementById("intro");
  const resizewatcher=new ResizeObserver(entries => {
    for (const entry of entries){
      if(entry.contentRect.width == 0) {
        document.getElementById("main-riddles").className = 'fade-in';
      }
        else{
        }   
      
    }
  })
  resizewatcher.observe(intro);
})

function nextRiddle(){
  var newRiddleCount = riddleCount + 1;
  if(newRiddleCount >= riddles.length){
    setFinished(true);
    return;
  }
  setRiddleCount(newRiddleCount);
  setCurrentRiddle(riddles[newRiddleCount]);
  setCorrect(false);
  setTaunt('');
}
  return (
    <div className="App">
      
      <div id='intro' className={ready ? 'fade-out' : 'fade-in'}>
     <h3 className='fade-in'>Welcome to Stella's Bday Riddles</h3>
     <h3 className='fade-in-slower'>Are you ready?</h3>
     <div>
      <div className='ready-buttons fade-in-slower'>
      <button className='btn btn-primary' onClick={e => {setReady(true)}}>Yes</button>
      <button className='btn btn-info' onClick={e => {setDisplaySupportVideo(true);}}>No</button>
      </div>
      {displaySupportVideo ? <div>
      <h3>Please watch this video to motivate you for the challenges ahead.</h3>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/oiXaT_1I-vw?si=uqoRW545BAsd7lFT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div> : <></>}
      </div>
      </div>
     <div className='hidden' id='main-riddles'>
      <div>
      <h5 className='timer-text'>Please ignore this timer. Totally unrelated to anything.</h5>
      <Countdown className='timer-text' date={finishDate} onComplete={() => setTimerComplete(true)}/>
      {timerComplete ? <h2>Oh the timer is up. Like i said it didn't mean anything. Everyone is so obsessed with time. But maybe check your email or something.</h2>: <></>}
      </div>
      
       
      
    {finished ? <h1>Thank you for playing Stella Riddles! I hope you had fun. Please give yoyo a big a kiss for doing all this. Are you happy now??? Huh! dhfopshfgopshgl;sdngld;fngdfl;gndflgn woop oopw</h1> : <div>
            {correct ? (<div><h1>Nice!</h1>
            <button className='btn btn-success' onClick={nextRiddle}>Next Riddle?</button>
            </div>): (
                <div>
                  <br></br>
           {currentRiddle.riddle}
            <input className='form-control' value={answer} onChange={e => setAnswer(e.target.value)} type="text"/>
            <button className='btn btn-primary' onClick={submitAnswer}>Submit</button>
            <h3>{taunt}</h3>
            </div>)}</div>} 
             </div>
    </div>
  );
}

export default App;
